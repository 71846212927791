var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quota-single" },
    [
      _c("div", { staticClass: "sample-day__time" }, [
        !_vm.isMobile
          ? _c("div", { staticClass: "desktopWrapper" }, [
              _c("span", { staticClass: "sample-day__time-text" }, [
                _vm._v("с"),
              ]),
              _c("span", { staticClass: "sample-day__time-val" }, [
                _vm._v(" " + _vm._s(_vm.getData.time_from) + " "),
              ]),
              _c("span", { staticClass: "sample-day__time-text" }, [
                _vm._v("по"),
              ]),
              _c("span", { staticClass: "sample-day__time-val" }, [
                _vm._v(" " + _vm._s(_vm.getData.time_to) + " "),
              ]),
            ])
          : _c("div", { staticClass: "mobileWrapper" }, [
              _c("span", { staticClass: "sample-day__time-val" }, [
                _vm._v(" " + _vm._s(_vm.getData.time_from) + " "),
              ]),
              _c("span", { staticClass: "sample-day__title-slash" }, [
                _vm._v("-"),
              ]),
              _c("span", { staticClass: "sample-day__time-val" }, [
                _vm._v(" " + _vm._s(_vm.getData.time_to) + " "),
              ]),
            ]),
        _vm.isMobile
          ? _c(
              "div",
              { staticClass: "sample-day__icons" },
              [
                !_vm.isSupport && !_vm.isViewer
                  ? _c("IconPenMobile", {
                      on: {
                        onClick: function ($event) {
                          _vm.editCulture = true
                        },
                      },
                    })
                  : _vm._e(),
                !_vm.isExporter && !_vm.isSupport && !_vm.isViewer
                  ? _c("IconTrashMobile", {
                      on: {
                        onClick: function ($event) {
                          _vm.deleteCulture = true
                        },
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "details-show-icon" },
                  [
                    _c("Icon", {
                      class: ["arrow", { rotated: _vm.isOpen }],
                      attrs: {
                        name: "ArrowDownIcon",
                        width: "22px",
                        height: "22px",
                      },
                      on: {
                        onClick: function ($event) {
                          _vm.isOpen = !_vm.isOpen
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("SampleContent", {
        attrs: {
          sample: _vm.getData,
          del: _vm.deleteCulture,
          edit: _vm.editCulture,
        },
        on: {
          emitClose: _vm.handleClose,
          updateBeforeDelete: function ($event) {
            return _vm.$emit("updateBeforeDeleted")
          },
        },
      }),
      _c(
        "el-collapse-transition",
        [
          _c("SampleShared", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen && _vm.isMobile,
                expression: "isOpen && isMobile",
              },
            ],
            staticClass: "mobile-detail",
            attrs: { sample: _vm.getData },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }