<template>
  <div class="quota-single">
    <div class="sample-day__time">
      <div v-if="!isMobile" class="desktopWrapper">
        <span class="sample-day__time-text">с</span>

        <span class="sample-day__time-val">
          {{ getData.time_from }}
        </span>

        <span class="sample-day__time-text">по</span>

        <span class="sample-day__time-val">
          {{ getData.time_to }}
        </span>
      </div>

      <div v-else class="mobileWrapper">
        <span class="sample-day__time-val">
          {{ getData.time_from }}
        </span>

        <span class="sample-day__title-slash">-</span>

        <span class="sample-day__time-val">
          {{ getData.time_to }}
        </span>
      </div>

      <div v-if="isMobile" class="sample-day__icons">
        <IconPenMobile
          v-if="!isSupport && !isViewer"
          @onClick="editCulture = true"
        />
        <IconTrashMobile
          v-if="!isExporter && !isSupport && !isViewer"
          @onClick="deleteCulture = true"
        />
        <div class="details-show-icon">
          <Icon
            name="ArrowDownIcon"
            width="22px"
            height="22px"
            :class="['arrow', { rotated: isOpen }]"
            @onClick="isOpen = !isOpen"
          />
        </div>
      </div>
    </div>

    <SampleContent
      :sample="getData"
      :del="deleteCulture"
      :edit="editCulture"
      @emitClose="handleClose"
      @updateBeforeDelete="$emit('updateBeforeDeleted')"
    />
    <el-collapse-transition>
      <SampleShared
        v-show="isOpen && isMobile"
        :sample="getData"
        class="mobile-detail"
      />
    </el-collapse-transition>
  </div>
</template>

<script>
import { GET_IS_MOBILE } from '@/store/actions'
import { GET_PREPARE_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import { mapGetters } from 'vuex'
import Icon from '@/UI/icon/Icon.vue'
import IconPenMobile from '@/UI/icon/IconTrashMobile'
import IconTrashMobile from '@/UI/icon/IconPenMobile'
import SampleContent from './SampleContent'
import SampleShared from './SampleShared'
export default {
  name: 'SampleSingle',
  components: {
    SampleShared,
    Icon,
    SampleContent,
    IconTrashMobile,
    IconPenMobile,
  },
  props: {
    sample: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      editCulture: false,
      deleteCulture: false,
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters({
      getCultures: GET_PREPARE_CULTURE_FROM_STATE,
      isMobile: GET_IS_MOBILE,
    }),
    getData() {
      return this.sample[0]
    },
  },
  methods: {
    handleClose() {
      this.editCulture = false
      this.deleteCulture = false
    },
  },
}
</script>

<style lang="sass">
.quota-single
  margin-bottom: 18px
  display: flex
  justify-content: space-between
  align-items: center
  &:last-of-type
    margin-bottom: 0
  .desktopWrapper
    display: flex

@media (max-width: 1200px)
  .quota-single
    flex-direction: column
    align-items: flex-start
    .sample-day
      &__title-slash
        margin: 0 4px
      &__time
        order: 1
        flex: auto
        align-items: center
        justify-content: space-between
        width: 100%
        margin: 22px 0 0
        .mobileWrapper
          display: flex
          align-items: center
          justify-content: center
        &-val
          background: #fff
          border: none
          font-size: 16px
          margin: 0
          padding: 0
      &__icons
        display: flex
        align-items: center
        justify-content: space-between
        column-gap: 12px
        .details-show-icon
          display: flex
          justify-content: center
          align-items: center
          width: 32px
          height: 32px
          border-radius: 8px
          background: #f4f4f5
          border: 1px solid #E4E7ED
          .arrow
            transition: transform 0.3s ease
            &.rotated
              transform: rotate(180deg)
            &.iq-icon *
              fill: none
              stroke: $fontColor

    .mobile-detail
      width: 100%
      order: 2
      padding-bottom: 4px
</style>
